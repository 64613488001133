<template>
    <div>
        <card-header title="Registration Notes" icon="fa-sticky-note"/>
        <card-toolbar>
            <button @click="$openCard('new-reg-note', {registrationId: props.registrationId}, card)"><i class="fas fa-plus mr-2"/>New Note</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <trip-note-list-item
                    :card="card"
                    :note="note"
                    v-for="note in notes"
                    :active="(child && child.definition.component === 'edit-reg-note') && child.props.noteId === note.id.toString()"
                    @click="$openCard('edit-reg-note', {registrationId: props.registrationId, noteId: note.id}, card)"
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import CardBody from "../../TIER/components/CardBody";

    import {client as http} from '../../http_client';
    import TripNoteListItem from "@/components/TripNoteListItem";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {TripNoteListItem, CardBody, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                notes: []
            }
        },
        methods: {
            loadNotes: function(force) {
                this.$emit('loading', true);
                http.get('/api/registrations/' + this.props.registrationId + '/notes', {force}).then(response => {
                    this.notes = response.data;
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadNotes(true);
            }
        },
        mounted() {
            this.loadNotes();
        }
    };
</script>

<style lang="scss" scoped>

</style>
